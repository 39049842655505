<template>
    <section class="side-box my-head">
        <h3 v-if="userInfo && userInfo.status===2" class="box-round head-tips">TIPS：用户名违规，请<a href="javascript:;" @click.prevent="$router.push('/my/accout')">立即修改</a></h3>
        <div class="head-title">
            <i class="icon icon-more" />
            <i class="icon icon-user" />
            <span class="head-title-txt">MySoul<strong>个人中心</strong></span>
        </div>
        <div v-if="userInfo" class="head-num">
            <router-link class="head-num-item" to="/my/order/3">
                待付款<i class="head-num-num">{{ userInfo.daifukuan || 0 }}</i>
            </router-link>
            <router-link class="head-num-item" to="/my/order/4">
                待发货<i class="head-num-num">{{ userInfo.daifahuo || 0 }}</i>
            </router-link>
            <router-link class="head-num-item" to="/my/message">
                我的消息<i class="head-num-num">{{ userInfo.news ? +userInfo.news > 99 ? `${userInfo.news}+` : userInfo.news : 0 }}</i>
            </router-link>
        </div>
    </section>
</template>
<script>
export default {
    name: 'MySideHead',
    props: ['userInfo']
}
</script>
<style lang="scss" scoped>
.side-box {
    margin-bottom: 10px;
    background-color: $white;
    &.my-head {
        padding: 20px;
    }
}
.head-tips {
    margin-bottom: 20px;
}
.head-title {
    padding-bottom: 20px;
    border-bottom: 1px dashed $lighter;
    .icon {
        margin-right: 10px;
    }
}
.head-title-txt {
    @include inlineBlock();
    margin-left: 5px;
    font-weight: bold;
    font-size: 15px;
    color: $red;
    strong {
        display: block;
        font-weight: bolder;
        font-size: 18px;
    }
}
.head-num {
    padding-top: 10px;
    text-align: center;
    color: $light;
}
.head-num-item {
    @include inlineBlock();
    margin: 0 20px;
}
.head-num-num {
    display: block;
    margin-top: 2px;
    font-weight: 700;
    font-size: 14px;
    color: $red;
}
</style>
