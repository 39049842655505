<template>
    <section class="side-box my-menu">
        <dl v-for="item in navList" :key="item.name" class="my-menu-item">
            <dt class="my-menu-title">{{ item.name }}</dt>
            <dd class="my-menu-content">
                <router-link v-for="child in item.children" :key="child.name" class="my-menu-child" :class="{ active: $route.fullPath===item.path }" :to="child.path">{{ child.name }}</router-link>
            </dd>
        </dl>
    </section>
</template>
<script>
export default {
    name: 'MySideMenu',
    data() {
        return {
            navList: [
                {
                    name: '我的订单',
                    children: [
                        { name: '全部订单', path: '/my/order/1' },
                        { name: '预订', path: '/my/order/2' },
                        { name: '待付款', path: '/my/order/3' },
                        { name: '待发货', path: '/my/order/4' },
                        { name: '已完成', path: '/my/order/5' },
                        { name: '已取消', path: '/my/order/6' }
                    ]
                },
                {
                    name: '我的售后',
                    children: [
                        { name: '售后申请', path: '/my/aftermarket/list' }
                    ]
                },
                {
                    name: '我的账户',
                    children: [
                        { name: '账户设置', path: '/my/accout' },
                        { name: '地址管理', path: '/my/address' }
                        // { name: '账户管理', path: '' }
                    ]
                },
                {
                    name: '我的',
                    children: [
                        { name: '我的K点', path: '/my/k-point' },
                        { name: '我的关注', path: '/my/follow' }
                    ]
                }
            ]
        }
    },
    watch: {
        '$route'(to, from) {
            // 滚动 到顶部
            // console.log('to', to, document.querySelector('html'))
            const appDom = document.querySelector('html')
            if (appDom) appDom.scrollTo(0, 0)
        }
    }
}
</script>
<style lang="scss" scoped>
.my-menu-title {
    @include lineHeight(44px);
    padding: 0 20px;
    border-left: 3px solid $red;
    font-size: 18px;
    background-color: $grayest;
}
.my-menu-content {
    padding: 10px 0;
    background-color: $white;
}
.my-menu-child {
    @include lineHeight(44px);
    display: block;
    padding: 0 20px;
    font-size: 16px;
    color: $dark;
    &.active {
        color: $reder;
    }
}
.router-link-active {
    color: $reder;
}
</style>
